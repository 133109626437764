import CodeExample from "components/docs/code-examples/CodeExample";
import CodeBlock from "components/docs/shared-components/code-block/CodeBlock";
import React from "react";
import { Col, Row } from "react-bootstrap";
import DocumentationSection from "../../components/docs/DocumentationSection";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import ParameterRow from "../../components/docs/ParameterRow";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";

const sampleRequestWithPagination = `
GET /api/ats/v1/candidates?page_size=20&cursor=cD0yMDIxLTA3LTI4KzE5JTNBMzglM0EzNi42NzUxNTMlMkIwMCUzQTAw 
Host: api.merge.dev
X-Account-Token: {Linked Account Token Here}
Authorization: Bearer {Production API Key Here}
`;

interface Props {
  location: RouterLocation;
}

function DocumentationPaginationPage({ location }: Props) {
  const title = "Pagination";
  const description = "Learn how to paginate bulk data requests to the Merge API.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>
          Any data you interact with via the Merge API is able to be paginated. Pagination is
          specified via the <code>cursor</code> and <code>page_size</code> query parameters. Ensure
          that all query parameters, aside from <code>cursor</code> and <code>page_size</code>,
          remain consistent across pagination to maintain the integrity of your data retrieval. The{" "}
          <code>next</code> and <code>previous</code> cursors are attached to paginated API
          responses. Their values inform the <code>cursor</code> where to point to next.
        </p>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Query Parameters">
        <>
          <ParameterRow
            key="cursor"
            name="cursor"
            type="String"
            required={false}
            isShowingRequiredFlag
            description="Denotes the starting position in the data list from where a paginated API endpoint 
                  should return bulk data. Get this value from the `next` or `previous` property of any previous paginated response. 
                  When `next` or `previous` is null there are no more pages to paginate through."
          />
          <ParameterRow
            key="page_size"
            name="page_size"
            type="Integer"
            required={false}
            isShowingRequiredFlag
            description="Limit on number of objects to return per request. Defaults to 30, maximum of 100."
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Sample HTTP Request">
        <>
          <p>
            Below is an sample request using pagination in <code>HTTP</code>. The{" "}
            <code>page_size</code> is set to 20, and the <code>cursor</code> is pointing to a value
            for the next page.
          </p>
          <CodeBlock
            language="bash"
            text={sampleRequestWithPagination}
            hasLineNumbers={false}
            hideCopyButtonWithHeader
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Getting the Cursor">
        <>
          <p>
            In the response payload of an API request to a paginated endpoint, you can find{" "}
            <code>next</code> and <code>previous</code> cursors.
          </p>
          <CodeExample
            folder="basics/pagination/paginated-api-response-payload"
            codeBlockName="Paginated API Response Payload"
            colorScheme="light"
            hideCopyButtonWithHeader
            hasLineNumbers={false}
          />
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Using the Cursor">
        <>
          <p>
            These cursors can be attached to future requests to paginated API endpoints to query the
            next (or previous) page of results, as demonstrated in the following code sample using
            the Merge SDK:
          </p>
          <CodeExample
            folder="basics/pagination/sdk-pagination"
            codeBlockName="Paginating via Merge SDK"
            style={{ maxHeight: "none" }}
          />
          <p>
            If you don&apos;t want to use the Merge SDK and want to form your own API requests
            instead, you can do so as illustrated below:
          </p>
          <CodeExample
            folder="basics/pagination/api-pagination"
            codeBlockName="Paginating via Manual API Request"
            style={{ maxHeight: "none" }}
          />
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} style={{ marginTop: "12px" }} />
    </StaticPageContainer>
  );
}

export default DocumentationPaginationPage;
